import React, { useEffect } from 'react';

import { useAuth } from '../../hooks/useAuth';

const isBrowser = () => typeof window !== 'undefined';

const LogowaniePage = () => {
	const auth = isBrowser()
		? useAuth()
		: {
				user: '',
				role: '',
				verifyUser: () => {},
				signIn: () => {},
				signOut: () => {},
		  };
	useEffect(() => {
		auth.signOut();
	});
	return null;
};

export default LogowaniePage;
